import { CameraViewEnum, Domain, IncidentGroupingFieldEnum, PlaybackSpeedEnum } from "@dwclient/domain";
const ruEnumResources = {
    InspectionPeriodicity: {
        [Domain.InspectionPeriodicity.MONTH]: "Месяц",
        [Domain.InspectionPeriodicity.WEEK]: "Неделя",
        [Domain.InspectionPeriodicity.DAY]: "Сутки",
        [Domain.InspectionPeriodicity.SHIFT]: "Смена",
    },
    DeviceStatus: {
        [Domain.DeviceStatus.FAULTY]: "Неисправные",
        [Domain.DeviceStatus.FREE]: "Свободные",
        [Domain.DeviceStatus.ISSUED]: "Выданные",
        [Domain.DeviceStatus.LOST]: "Утерянные",
        [Domain.DeviceStatus.UNIDENTIFIED]: "Не опознанные",
        [Domain.DeviceStatus.ARCHIVED]: "Архивные",
    },
    names: {
        cameraView: "Преднастроенная модель",
        sensorType: "Тип датчика",
    },
    shortGenerationPeriod: {
        [Domain.ReportGenerationPeriod.THIS_DAY]: "Текущие сутки",
        [Domain.ReportGenerationPeriod.PREVIOUS_DAY]: "Прошлые сутки",
        [Domain.ReportGenerationPeriod.THIS_WEEK]: "Текущая неделя",
        [Domain.ReportGenerationPeriod.PREVIOUS_WEEK]: "Прошлая неделя",
        [Domain.ReportGenerationPeriod.THIS_MONTH]: "Текущий месяц",
        [Domain.ReportGenerationPeriod.PREVIOUS_MONTH]: "Прошлый месяц",
    },
    AuditEventType: {
        [Domain.AuditEventType.ARCHIVE_USER]: "Архивирование пользователя",
        [Domain.AuditEventType.CREATE_NEW_USER]: "Создание нового пользователя",
        [Domain.AuditEventType.INCIDENT_CARD_VIEW]: "Просмотр события",
        [Domain.AuditEventType.TAG_ADDED]: "Добавлен тег",
        [Domain.AuditEventType.TAG_DELETED]: "Удалён тег",
        [Domain.AuditEventType.EVENT_STATUS_CHANGED]: "Изменён статус события",
        [Domain.AuditEventType.COMMENT_ADDED]: "Добавлен комментарий",
        [Domain.AuditEventType.LOGIN]: "Вход пользователя в систему",
        [Domain.AuditEventType.LOGIN_FAILED]: "Ввод неверного логина/пароля",
        [Domain.AuditEventType.LOGOUT]: "Выход пользователя из системы",
        [Domain.AuditEventType.RESTORE_USER]: "Восстановление пользователя",
        [Domain.AuditEventType.USER_PASSWORD_CHANGED]: "Изменение пароля пользователя",
        [Domain.AuditEventType.USER_ROLE_CHANGED]: "Изменение роли пользователя",
        [Domain.AuditEventType.USER_TO_PERSON_LINK_CHANGE]: "Привязка / отвязка Сотрудника",
        [Domain.AuditEventType.FILE_ADDED]: "Добавлен файл",
        [Domain.AuditEventType.FILE_DELETED]: "Удалён файл",
        [Domain.AuditEventType.MESSAGE_SENT_BUSINESS]: "Выполнена автоматическая рассылка уведомлений",
        [Domain.AuditEventType.MESSAGE_SENT_OPERATOR]: "Выполнена рассылка уведомлений по инициативе оператора",
        [Domain.AuditEventType.OBJECT_CREATED]: "Создание нового объекта",
        [Domain.AuditEventType.OBJECT_UPDATED]: "Редактирование существующего объекта",
    },
    auditComment: {
        [Domain.AuditEventType.ARCHIVE_USER]: "Изменён пользователь: <login>",
        [Domain.AuditEventType.CREATE_NEW_USER]: "Создан пользователь: <login>",
        [Domain.AuditEventType.INCIDENT_CARD_VIEW]: "Событие просмотрено оператором",
        [Domain.AuditEventType.TAG_ADDED]: 'Добавлен тег "<tag>"',
        [Domain.AuditEventType.TAG_DELETED]: 'Удалён тег "<tag>"',
        [Domain.AuditEventType.EVENT_STATUS_CHANGED]: 'Событие переведено в статус "<status>"',
        [Domain.AuditEventType.COMMENT_ADDED]: "Добавлен комментарий",
        [Domain.AuditEventType.LOGIN]: "",
        [Domain.AuditEventType.LOGIN_FAILED]: "",
        [Domain.AuditEventType.LOGOUT]: "",
        [Domain.AuditEventType.RESTORE_USER]: "Изменён пользователь: <login>",
        [Domain.AuditEventType.USER_PASSWORD_CHANGED]: "Изменён пользователь: <login>",
        [Domain.AuditEventType.USER_ROLE_CHANGED]: "Изменён пользователь: <login>\nДобавленные роли: <addedRoleNames>\nУдалённые роли: <removedRoleNames>",
        [Domain.AuditEventType.USER_TO_PERSON_LINK_CHANGE]: "Изменён пользователь: <login>\nСтарая связь: <oldPerson.lastName> <oldPerson.firstName> <oldPerson.middleName>(<oldPerson.id>)\nНовая связь: <newPerson.lastName> <newPerson.firstName> <newPerson.middleName>(<newPerson.id>)",
        [Domain.AuditEventType.FILE_ADDED]: 'Добавлен файл  "<file>"',
        [Domain.AuditEventType.FILE_DELETED]: 'Удалён файл "<file>"',
        [Domain.AuditEventType.MESSAGE_SENT_BUSINESS]: 'Сообщение с текстом "<message>" отправлено сотруднику <addresseeFio>',
        [Domain.AuditEventType.MESSAGE_SENT_OPERATOR]: 'Сообщение с текстом "<message>" отправлено сотруднику <addresseeFio>',
        [Domain.AuditEventType.OBJECT_CREATED]: "Создан объект: <object>",
        [Domain.AuditEventType.OBJECT_UPDATED]: "Изменён объек: <object>",
    },
    deliveryChannel: {
        [Domain.DeliveryChannel.TELEGRAM]: "Телеграм",
        [Domain.DeliveryChannel.EMAIL]: "E-mail",
    },
    reportSchedulePeriodicity: {
        [Domain.ReportSchedulePeriodicity.DAY]: "День",
        [Domain.ReportSchedulePeriodicity.WEEK]: "Неделя",
        [Domain.ReportSchedulePeriodicity.MONTH]: "Месяц",
    },
    "incidentPriority": {
        all: "Все",
        [Domain.IncidentPriority.INFO]: "Инфо",
        [Domain.IncidentPriority.ATTENTION]: "Внимание",
        [Domain.IncidentPriority.ALERT]: "Тревога",
    },
    "healthStatus": {
        [Domain.HealthStatus.GOOD]: "Хорошее",
        [Domain.HealthStatus.WARNING]: "Требует внимания",
        [Domain.HealthStatus.CRITICAL]: "Критическое",
        [Domain.HealthStatus.FATAL]: "Необходима помощь",
    },
    "cesium": {
        ShadowMode: {
            1: "Объект отбрасывает и получает тени",
            0: "Объект не отбрасывает и не получает тени",
            3: "Объект только получает тени",
            2: "Объект только отбрасывает тени",
        },
    },
    groupingType: {
        [IncidentGroupingFieldEnum.PRIORITY]: "Приоритет события",
        [IncidentGroupingFieldEnum.STATUS]: "Статус события",
        [IncidentGroupingFieldEnum.INCIDENT_TYPE]: "Тип события",
    },
    infoType: {
        [Domain.InfoType.TEMPERATURE]: "Температура воздуха",
        [Domain.InfoType.PULSE]: "ЧСС",
    },
    connectionStatus: {
        [Domain.ConnectionStatus.ONLINE]: "Онлайн",
        [Domain.ConnectionStatus.OFFLINE]: "Офлайн",
    },
    errorResponseCode: {
        [Domain.ErrorResponseCode.UNKNOWN]: "Во время выполнения запроса произошла неизвестная ошибка",
        [Domain.ErrorResponseCode.FORMATTING_ERROR]: "Во время выполнения запроса произошла неизвестная ошибка",
        [Domain.ErrorResponseCode.TIMEOUT]: "Превышено время ожидания ответа от сервера",
        [Domain.ErrorResponseCode.VALIDATION_ERROR]: "Выявлены ошибки в заполнении полей",
        [Domain.ErrorResponseCode.NOT_IMPLEMENTED]: "Во время выполнения запроса произошла неизвестная ошибка",
        [Domain.ErrorResponseCode.PARSING_ERROR]: "Во время выполнения запроса произошла неизвестная ошибка",
        [Domain.ErrorResponseCode.CLICKHOUSE_ERROR]: "Во время выполнения запроса произошла неизвестная ошибка",
        [Domain.ErrorResponseCode.DATABASE_ERROR]: "Во время выполнения запроса произошла неизвестная ошибка",
    },
    cameraView: {
        [CameraViewEnum.Box]: "Корпусная камера",
        [CameraViewEnum.Dome]: "Купольная камера",
    },
    SensorType: {
        [Domain.SensorType.CO2]: "Газоанализатор",
        [Domain.SensorType.RFID_READER]: "Считыватель пропуска",
        [Domain.SensorType.CONTROLLED_RELAY]: "Управляемое реле",
    },
    playbackSpeed: {
        [PlaybackSpeedEnum.x1]: "x1 (нормальная)",
        [PlaybackSpeedEnum.x2]: "x2",
        [PlaybackSpeedEnum.x8]: "x8",
        [PlaybackSpeedEnum.x32]: "x32",
        [PlaybackSpeedEnum.x100]: "x100",
        [PlaybackSpeedEnum.x500]: "x500",
        [PlaybackSpeedEnum.x1000]: "x1000",
    },
    gender: {
        [Domain.Gender.NOT_SPECIFIED]: "Не указан",
        [Domain.Gender.MALE]: "Мужской",
        [Domain.Gender.FEMALE]: "Женский",
    },
};
export default ruEnumResources;
